import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import Layout from "../components/layout";
import useWindowDimensions from "../components/hooks/useWindowDimensions";
import { NetlifyForm, Honeypot, Recaptcha } from "react-netlify-forms";

import "react-toastify/dist/ReactToastify.css";
import "../styles/templates/contact.sass";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Contact = ({ pageContext, location }) => {
  const { slugref, locale } = pageContext;
  const { home, contact } = pageContext.data.data;
  const { innerHeight, innerWidth } = useWindowDimensions();
  const [toggle, setToggle] = useState(false);

  /**SSR! */
  //if (typeof window === `undefined`) return <></>;
  return (
    <Layout
      location={location}
      data={home}
      toggle={toggle}
      setToggle={setToggle}
      slugref={slugref}
    >
      {typeof window !== `undefined` && (
        <section
          className="pageOption posabs"
          style={{ height: innerHeight - 109 }}
        >
          <div
            to={`/${home.locale !== "de" ? `${home.locale}/` : ""}${
              home.slug[0].portfolio
            }`}
            href="#"
            className="option bg-back"
            data-inf=""
            style={{
              width: `${0.5 * innerWidth +
                324.7543 +
                (0.725 * innerHeight - 456.75)}px`,
              left: 0,
              backgroundColor: "rgba(226, 213, 203, 1)",
            }}
          ></div>
          <div></div>
        </section>
      )}
      <section>
        <article className="contact___text-container">
          <div className="contact___text-wrap">
            <h1
              className="contact___title-wrap"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              {contact.interest}
            </h1>
            <h2
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              {contact.writeMe}
            </h2>
            <div
              className="form-wrapper"
              data-sal="slide-up"
              data-sal-delay="900"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              <NetlifyForm
                name="Contact Form"
                honeypotName="bot-field"
                enableRecaptcha
                onSuccess={(response, context) => {
                  console.log("Successfully sent form data to Netlify Server");
                  context.formRef.current.reset();
                }}
              >
                {({ handleChange, success, error }) => (
                  <>
                    <Honeypot />
                    <Recaptcha
                      siteKey={"6Lf2sqcZAAAAAGy1SK4zegk9VJaYFhviTLgK9T8p"}
                      invisible
                    />
                    {success && (
                      <p sx={{ variant: "alerts.success", p: 3 }}>
                        {locale !== "de"
                          ? "Thanks for your message!"
                          : "Vielen Dank für die Nachricht!"}
                      </p>
                    )}
                    {error && (
                      <p sx={{ variant: "alerts.muted", p: 3 }}>
                        {locale !== "de"
                          ? "Sorry, there was a problem sending the message."
                          : "Entschuldigen Sie, die Nachricht konnte nicht übermittelt werden."}
                      </p>
                    )}
                    <div>
                      <TextField
                        id="name"
                        label="Name"
                        name="name"
                        onChange={handleChange}
                        required
                      />
                      <TextField
                        id="email"
                        label="Email"
                        name="email"
                        onChange={handleChange}
                        required
                      />
                      <TextField
                        id="text"
                        label="Text"
                        multiline
                        rowsMax={10}
                        name="text"
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Icon>send</Icon>}
                        type="submit"
                      >
                        Send
                      </Button>
                    </div>
                  </>
                )}
              </NetlifyForm>
            </div>
          </div>
        </article>
      </section>
    </Layout>
  );
};

export default Contact;
